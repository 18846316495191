import {
  Button,
  fieldToTextField,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";

import { AdobeOauthAccount, ConnectedApps } from "./data/types";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 490,
  },
  title: {
    display: "block",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

interface AdobeOauthAccountDialogProps {
  oauthAccount?: AdobeOauthAccount;
  addOauthAccount: ConnectedApps["addAdobeOauthAccount"];
  updateOauthAccount: ConnectedApps["updateAdobeOauthAccount"];
  onClose: () => void;
}

interface FormValues {
  clientId: string;
  clientSecret: string;
}

export function AdobeOauthAccountDialog({
  oauthAccount,
  addOauthAccount,
  updateOauthAccount,
  onClose,
}: AdobeOauthAccountDialogProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const formikRef = React.useRef<FormikProps<FormValues> | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    formikRef.current?.resetForm();
    setOpen(Boolean(oauthAccount));
  }, [oauthAccount]);

  const [saving, setSaving] = React.useState(false);

  const isNew = !oauthAccount?.id?.length;

  const validationSchema = Yup.object().shape({
    clientId: Yup.string().required(t("required")),
    clientSecret: Yup.string().required(t("required")),
  });

  async function handleSave(submitForm: () => Promise<boolean>): Promise<void> {
    if (saving) return;

    setSaving(true);
    const result = await submitForm();
    setSaving(false);
    if (result) setOpen(false);
  }

  return (
    <Formik
      initialValues={{
        clientId: oauthAccount?.clientId ?? "",
        clientSecret: "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={({ clientId, clientSecret }) => {
        if (isNew) {
          return addOauthAccount(clientId, clientSecret);
        }
        return updateOauthAccount(oauthAccount.id, clientId, clientSecret);
      }}
      innerRef={formikRef}
    >
      {({ submitForm }) => (
        <Dialog
          open={open}
          onClose={() => !saving && setOpen(false)}
          TransitionProps={{ onExited: onClose }}
          classes={{ paper: classes.dialog }}
        >
          <DialogContent>
            <Typography variant="h2SemiBold" className={classes.title}>
              {isNew
                ? t("adobe.addOauthAccount")
                : t("adobe.updateOauthAccount")}
            </Typography>
            <Field name="clientId">
              {(props: FieldProps<string>) => (
                <TextField
                  id="client-id"
                  {...fieldToTextField(props)}
                  label={t("adobe.clientId")}
                  className={classes.field}
                  data-testid="adobe-edit-oauth-client-id"
                />
              )}
            </Field>
            <Field name="clientSecret">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  id="client-secret"
                  label={t("adobe.clientSecret")}
                  className={classes.field}
                  data-testid="adobe-edit-oauth-client-secret"
                />
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              disabled={saving}
              variant="outlined"
              size="large"
            >
              {t("cancelButton")}
            </Button>
            <Button
              onClick={() => handleSave(submitForm)}
              loading={saving}
              variant="contained"
              color="primary"
              size="large"
              data-testid="adobe-edit-oauth-save"
            >
              {isNew ? t("addButton") : t("updateButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
