import React from "react";
import { useTranslation, Typography } from "@lumar/shared";
import LogzioLogo from "./logo/Logzio.png";
import {
  AccountStatus,
  Cell,
  ConnectedAppsContainer,
  EditButton,
  RemoveButton,
} from "./ConnectedAppsContainer";
import { LogzioAccountDialog } from "./LogzioAccountDialog";
import { LogzioAccount } from "./data/types";
import { ConnectedAppsContext } from "./ConnectedApps";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secondaryLabel: {
    color: theme.palette.grey[500],
  },
}));

export function LogzioAccounts(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const [account, setAccount] = React.useState<LogzioAccount | undefined>(
    undefined,
  );

  const {
    logzioAccounts,
    addLogzioAccount,
    updateLogzioAccount,
    removeLogzioAccount,
  } = React.useContext(ConnectedAppsContext);

  return (
    <>
      <ConnectedAppsContainer
        logo={LogzioLogo}
        logoAlt="Logz.io logo"
        title={t("logzio.title")}
        noAccountMessage={t("logzio.noAccountMessage")}
        addon="logzio"
        addonMessage={t("logzio.description")}
        primaryButton={{
          label: t("logzio.addAccount"),
          onClick: () =>
            setAccount({ id: "", label: "", token: "", isValid: true }),
        }}
        accounts={logzioAccounts}
        renderRow={(account) => (
          <>
            <Cell width="auto">
              <Typography variant="captionSemiBold">{account.label}</Typography>
            </Cell>
            <Cell width="32%">
              <Typography
                variant="captionMedium"
                className={classes.secondaryLabel}
              >
                {account.token}
              </Typography>
            </Cell>
            <Cell width="12%">
              <AccountStatus isValid={account.isValid} />
            </Cell>
            <Cell width="40px">
              <EditButton onEdit={() => setAccount(account)} />
            </Cell>
            <Cell width="40px">
              <RemoveButton
                title={t("logzio.removeTitle")}
                description={t("logzio.removeDescription")}
                onRemove={() => removeLogzioAccount(account.id)}
              />
            </Cell>
          </>
        )}
        data-testid="connected-apps-logzio"
      />
      <LogzioAccountDialog
        account={account}
        addAccount={addLogzioAccount}
        updateAccount={updateLogzioAccount}
        onClose={() => setAccount(undefined)}
      />
    </>
  );
}
