var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloClient, ApolloLink, InMemoryCache, } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { relayStylePagination } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { extractFiles } from "extract-files";
import { HttpLink } from "@apollo/client/core";
import { mergeArrayByOrder } from "./helpers/mergeArrayByOrder";
/**
 * `Report.reportTrend` array entries have no identity
 * and the array never changes over time because
 * it is generated once when the crawl finishes.
 * This policy makes InMemoryCache merge array objects
 * without removing already cached fields but
 * updates them or adds new ones if they were queried.
 *
 * @author Michal Pietraszko
 */
function reportTrendFieldPolicy() {
    return {
        merge(existing, incoming, { mergeObjects }) {
            if (!existing) {
                return incoming;
            }
            if (existing === incoming) {
                return existing;
            }
            function findExistingTrendItem(incoming) {
                if (incoming.crawlId) {
                    return existing === null || existing === void 0 ? void 0 : existing.find((x) => x.crawlId === incoming.crawlId);
                }
                if (incoming.createdAt) {
                    return existing === null || existing === void 0 ? void 0 : existing.find((x) => x.createdAt === incoming.createdAt);
                }
                return undefined;
            }
            if (existing.length !== incoming.length) {
                return incoming.map((incomingTrendItem) => {
                    var _a;
                    return mergeObjects((_a = findExistingTrendItem(incomingTrendItem)) !== null && _a !== void 0 ? _a : {}, incomingTrendItem);
                });
            }
            return incoming.map((incomingTrendItem) => {
                var _a;
                return mergeObjects((_a = findExistingTrendItem(incomingTrendItem)) !== null && _a !== void 0 ? _a : {}, incomingTrendItem);
            });
        },
    };
}
export const apolloInMemoryCacheOptions = {
    typePolicies: {
        UserAgent: {
            keyFields: ["code"],
        },
        ReportCategory: {
            keyFields: ["code"],
        },
        Crawl: {
            fields: {
                healthScore: {
                    keyArgs: ["reportCategoryCode", "segmentId"],
                },
                reports: relayStylePagination(["filter", "reportTemplateFilter"]),
                crawlSegments: relayStylePagination(["filter"]),
            },
        },
        Project: {
            fields: {
                healthScore: {
                    keyArgs: ["reportCategoryCode", "segmentId"],
                },
                legacyTasks: relayStylePagination(["filter", "orderBy"]),
                crawls: relayStylePagination(["filter"]),
                segments: relayStylePagination(),
                urlFileUploads: relayStylePagination(),
            },
        },
        User: {
            fields: {
                accounts: relayStylePagination(),
                accountsUsers: relayStylePagination(),
                userKeys: relayStylePagination(["filter"]),
            },
        },
        Account: {
            fields: {
                monitorNotifications: relayStylePagination(["filter", "projectIds"]),
                monitorHealthScoreNotifications: relayStylePagination([
                    "filter",
                    "projectIds",
                ]),
                projects: relayStylePagination(["filter"]),
                monitorProjects: relayStylePagination(["filter"]),
                accountUsers: relayStylePagination(),
                legacyTasks: relayStylePagination(["filter", "orderBy"]),
                customDashboardCollections: relayStylePagination(),
            },
        },
        CrawlSegment: {
            // FIXME: Use `crawlId` and `segmentId` directly after https://lumarhq.atlassian.net/browse/GA-2975 is fixed.
            //keyFields: ["crawlId", "segmentId"],
            keyFields: ["crawl", ["id"], "segment", ["id"]],
        },
        TestSuite: {
            fields: {
                tests: relayStylePagination(),
            },
        },
        CustomDashboardCollection: {
            fields: {
                customViews: relayStylePagination(),
            },
        },
        CustomDashboard: {
            fields: {
                customViews: relayStylePagination(),
            },
        },
        CrawlSetting: {
            fields: {
                customExtractions: {
                    merge: mergeArrayByOrder,
                },
            },
        },
        CustomExtractionSetting: {
            keyFields: false,
        },
        CustomReport: {
            keyFields: ["crawlId", "segmentId", "customReportTemplate", ["id"]],
        },
        ReportStat: {
            keyFields: ["crawlId", "segmentId", "reportTemplateCode"],
        },
        Report: {
            fields: {
                diffReportTotals: {
                    keyArgs: ["filter", "includeSelf"],
                },
                reportTrend: reportTrendFieldPolicy(),
                rowsWithAllMetrics: relayStylePagination([
                    "filter",
                    "orderBy",
                    "before",
                    "first",
                    "after",
                    "last",
                ]),
            },
        },
        Sitemap: {
            keyFields: ["urlDigest"],
        },
        Query: {
            fields: {
                getIndustryBenchmarks: relayStylePagination(["filter", "orderBy"]),
                getReportTemplates: relayStylePagination([
                    "filter",
                    "orderBy",
                    "before",
                    "first",
                    "after",
                    "last",
                ]),
                getReportCategories: relayStylePagination(),
                getHealthScoreTrendForCrawl: relayStylePagination([
                    "crawlId",
                    "filter",
                    "orderBy",
                ]),
                getHealthScoreTrendForCrawlSegment: relayStylePagination([
                    "crawlId",
                    "segmentId",
                    "filter",
                    "orderBy",
                ]),
            },
        },
    },
};
export const apolloClientDefaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
    },
    query: {
        fetchPolicy: "no-cache",
    },
};
export function createApolloClient({ name, getToken, version, enableDevTools, rollbarInstance, locale, logout, }) {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    if (!apiBaseUrl) {
        throw Error("Environment not correctly set up. 'REACT_APP_API_BASE_URL' variable is missing.");
    }
    const graphqlUrl = new URL("graphql", apiBaseUrl);
    const contextAndErrorLink = ApolloLink.from([
        setContext((_1, _a) => __awaiter(this, [_1, _a], void 0, function* (_, { headers }) {
            const authToken = yield getToken();
            // eslint-disable-next-line fp/no-let
            let authHeader = {};
            if ((authToken === null || authToken === void 0 ? void 0 : authToken.type) === "share")
                // eslint-disable-next-line fp/no-mutation
                authHeader = { "X-Auth-Token": authToken.token };
            if ((authToken === null || authToken === void 0 ? void 0 : authToken.type) === "auth0")
                // eslint-disable-next-line fp/no-mutation
                authHeader = { Authorization: `Bearer ${authToken.token}` };
            return {
                headers: Object.assign(Object.assign(Object.assign({}, authHeader), { "x-graph-locale": locale }), headers),
            };
        })),
        onError(({ graphQLErrors, networkError, operation }) => {
            const { Rollbar } = window;
            const rollbar = rollbarInstance !== null && rollbarInstance !== void 0 ? rollbarInstance : Rollbar;
            if (networkError &&
                "statusCode" in networkError &&
                networkError.statusCode === 401) {
                logout();
            }
            if (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) {
                if (rollbar === null || rollbar === void 0 ? void 0 : rollbar.options.enabled) {
                    rollbar.error(operation.operationName +
                        " graphql error: " +
                        graphQLErrors[0].message, Object.assign(Object.assign({}, graphQLErrors), { operationName: operation.operationName, variables: operation.variables, networkError }));
                }
                else {
                    console.error(operation.operationName +
                        " graphql error: " +
                        graphQLErrors[0].message, Object.assign(Object.assign({}, graphQLErrors), { operationName: operation.operationName, variables: operation.variables, networkError }));
                }
            }
        }),
    ]);
    const batchHttpLink = new BatchHttpLink({
        uri: graphqlUrl.href,
        batchMax: 25,
        batchInterval: 20,
    });
    const individualHttpLink = new HttpLink({
        uri: graphqlUrl.href,
    });
    const uploadLink = createUploadLink({
        uri: graphqlUrl.href,
        headers: { "Apollo-Require-Preflight": "true" },
    });
    const splitLink = ApolloLink.split((operation) => extractFiles(operation).files.size > 0, ApolloLink.concat(contextAndErrorLink, uploadLink), ApolloLink.split((operation) => !!operation.getContext().includeInBatch, ApolloLink.concat(contextAndErrorLink, batchHttpLink), ApolloLink.concat(contextAndErrorLink, individualHttpLink)));
    return new ApolloClient({
        name,
        uri: graphqlUrl.href,
        version,
        resolvers: [],
        link: splitLink,
        cache: new InMemoryCache(apolloInMemoryCacheOptions),
        defaultOptions: apolloClientDefaultOptions,
        connectToDevTools: enableDevTools,
    });
}
