import React from "react";
import { useTranslation, Typography } from "@lumar/shared";

import SplunkLogo from "./logo/Splunk.png";
import { SplunkAccount } from "./data/types";
import {
  AccountStatus,
  Cell,
  ConnectedAppsContainer,
  EditButton,
  RemoveButton,
} from "./ConnectedAppsContainer";
import { SplunkAccountDialog } from "./SplunkAccountDialog";
import { ConnectedAppsContext } from "./ConnectedApps";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secondaryLabel: {
    color: theme.palette.grey[500],
  },
}));

export function SplunkAccounts(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const {
    splunkAccounts,
    addSplunkAccount,
    updateSplunkAccount,
    removeSplunkAccount,
  } = React.useContext(ConnectedAppsContext);

  const [account, setAccount] = React.useState<SplunkAccount | undefined>(
    undefined,
  );

  return (
    <>
      <ConnectedAppsContainer
        logo={SplunkLogo}
        logoAlt="Splunk logo"
        title={t("splunk.title")}
        noAccountMessage={t("splunk.noAccountMessage")}
        addon="splunk"
        addonMessage={t("splunk.description")}
        primaryButton={{
          label: t("splunk.addAccount"),
          onClick: () =>
            setAccount({ id: "", url: "", username: "", isValid: true }),
        }}
        accounts={splunkAccounts}
        renderRow={(account) => (
          <>
            <Cell width="auto">
              <Typography variant="captionSemiBold">{account.url}</Typography>
            </Cell>
            <Cell width="18%">
              <Typography
                variant="captionMedium"
                className={classes.secondaryLabel}
              >
                {account.username}
              </Typography>
            </Cell>
            <Cell width="18%">
              <Typography
                variant="captionMedium"
                className={classes.secondaryLabel}
              >
                {account.staticIp ?? t("splunk.dynamic")}
              </Typography>
            </Cell>
            <Cell width="12%">
              <AccountStatus isValid={account.isValid} />
            </Cell>
            <Cell width="40px">
              <EditButton onEdit={() => setAccount(account)} />
            </Cell>
            <Cell width="40px">
              <RemoveButton
                title={t("splunk.removeTitle")}
                description={t("splunk.removeDescription")}
                onRemove={() => removeSplunkAccount(account.id)}
              />
            </Cell>
          </>
        )}
        data-testid="connected-apps-splunk"
      />
      <SplunkAccountDialog
        account={account}
        addAccount={addSplunkAccount}
        updateAccount={updateSplunkAccount}
        onClose={() => setAccount(undefined)}
      />
    </>
  );
}
