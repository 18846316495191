import React from "react";
import { useTranslation, Chip, Typography, useSession } from "@lumar/shared";

import AdobeLogo from "./logo/Adobe.png";
import { AdobeJwtAccount, AdobeOauthAccount } from "./data/types";
import {
  AccountStatus,
  Cell,
  ConnectedAppsContainer,
  EditButton,
  RemoveButton,
} from "./ConnectedAppsContainer";
import { AdobeJwtAccountDialog } from "./AdobeJwtAccountDialog";
import { ConnectedAppsContext } from "./ConnectedApps";
import { makeStyles, Tooltip } from "@material-ui/core";
import { AdobeOauthAccountDialog } from "./AdobeOauthAccountDialog";

const useStyles = makeStyles((theme) => ({
  secondaryLabel: {
    color: theme.palette.grey[500],
  },
}));

export function AdobeAccounts(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");
  const { hasFeatureFlagEnabled } = useSession();
  const isOauthConnectionEnabled = hasFeatureFlagEnabled("adobe-oauth");

  const {
    adobeAccounts,
    addAdobeJwtAccount,
    updateAdobeJwtAccount,
    removeAdobeAccount,
    deleteAdobeJwtAccount,
    addAdobeOauthAccount,
    updateAdobeOauthAccount,
    deleteAdobeOauthAccount,
  } = React.useContext(ConnectedAppsContext);

  const [jwtAccount, setJwtAccount] = React.useState<
    AdobeJwtAccount | undefined
  >(undefined);
  const [oauthAccount, setOauthAccount] = React.useState<
    AdobeOauthAccount | undefined
  >(undefined);

  return (
    <>
      <ConnectedAppsContainer
        logo={AdobeLogo}
        logoAlt="Adobe logo"
        title={t("adobe.title")}
        noAccountMessage={t("adobe.noAccountMessage")}
        addon="adobe-analytics"
        addonMessage={t("adobe.description")}
        primaryButton={{
          label: t("adobe.addJwtAccount"),
          onClick: () =>
            setJwtAccount({
              type: "modern",
              id: "",
              clientSecret: "",
              clientId: "",
              orgId: "",
              privateKey: "",
              technicalAccountId: "",
              isValid: true,
            }),
        }}
        secondaryButton={
          isOauthConnectionEnabled
            ? {
                label: t("adobe.addOauthAccount"),
                onClick: () =>
                  setOauthAccount({
                    type: "oauth",
                    id: "",
                    clientSecret: "",
                    clientId: "",
                    isValid: true,
                  }),
              }
            : undefined
        }
        accounts={adobeAccounts}
        renderRow={(account) => {
          if (account.type === "deprecated") {
            return (
              <>
                <Cell>
                  <Typography variant="captionSemiBold">
                    {account.username}
                  </Typography>
                  <br />
                  <Chip label="deprecated" color="red" />
                </Cell>
                <Cell>
                  <Typography
                    variant="captionMedium"
                    className={classes.secondaryLabel}
                  >
                    {account.secret}
                  </Typography>
                </Cell>
                <Cell width="12%">
                  <AccountStatus isValid={account.isValid} />
                </Cell>
                <Cell width="80px">
                  <Tooltip title="You can't edit deprecated connections.">
                    <div style={{ display: "inline-block" }}>
                      <EditButton disabled />
                    </div>
                  </Tooltip>
                  <RemoveButton
                    title={t("adobe.removeTitle")}
                    description={t("adobe.removeDescription")}
                    onRemove={() => removeAdobeAccount(account.id)}
                  />
                </Cell>
              </>
            );
          }

          if (account.type === "modern") {
            return (
              <>
                <Cell>
                  <Typography variant="captionSemiBold" component="div">
                    {account.clientId}
                  </Typography>
                  <Typography variant="captionSemiBold" component="div">
                    {account.technicalAccountId}
                  </Typography>
                  <Typography variant="captionSemiBold" component="div">
                    {account.orgId}
                  </Typography>
                </Cell>
                <Cell>
                  <Typography
                    variant="captionMedium"
                    className={classes.secondaryLabel}
                    component="div"
                  >
                    {account.clientSecret}
                  </Typography>
                  <Typography
                    variant="captionMedium"
                    className={classes.secondaryLabel}
                    component="div"
                  >
                    {account.privateKey}
                  </Typography>
                </Cell>
                <Cell width="12%">
                  <AccountStatus isValid={account.isValid} />
                </Cell>
                <Cell width="80px">
                  <EditButton onEdit={() => setJwtAccount(account)} />
                  <RemoveButton
                    title={t("adobe.removeTitle")}
                    description={t("adobe.removeDescription")}
                    onRemove={() => deleteAdobeJwtAccount(account.id)}
                  />
                </Cell>
              </>
            );
          }

          return (
            <>
              <Cell>
                <Typography variant="captionSemiBold" component="div">
                  {account.clientId}
                </Typography>
              </Cell>
              <Cell>
                <Typography
                  variant="captionMedium"
                  className={classes.secondaryLabel}
                  component="div"
                >
                  {account.clientSecret}
                </Typography>
              </Cell>
              <Cell width="12%">
                <AccountStatus isValid={account.isValid} />
              </Cell>
              <Cell width="80px">
                <EditButton onEdit={() => setOauthAccount(account)} />
                <RemoveButton
                  title={t("adobe.removeTitle")}
                  description={t("adobe.removeDescription")}
                  onRemove={() => deleteAdobeOauthAccount(account.id)}
                />
              </Cell>
            </>
          );
        }}
        data-testid="connected-apps-adobe"
      />
      <AdobeJwtAccountDialog
        jwtAccount={jwtAccount}
        addJwtAccount={addAdobeJwtAccount}
        updateJwtAccount={updateAdobeJwtAccount}
        onClose={() => setJwtAccount(undefined)}
      />
      {isOauthConnectionEnabled ? (
        <AdobeOauthAccountDialog
          oauthAccount={oauthAccount}
          addOauthAccount={addAdobeOauthAccount}
          updateOauthAccount={updateAdobeOauthAccount}
          onClose={() => setOauthAccount(undefined)}
        />
      ) : null}
    </>
  );
}
