import { ApolloCache, Snackbar } from "@lumar/shared";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import {
  AddAdobeJwtConnectionMutation,
  AddAdobeOauthConnectionMutation,
  DeleteAdobeJwtConnectionMutation,
  DeleteAdobeOauthConnectionMutation,
  GetConnectedAppsDocument,
  GetConnectedAppsQuery,
  RemoveAdobeConnectionMutation,
  UpdateAdobeJwtConnectionMutation,
  UpdateAdobeOauthConnectionMutation,
  useAddAdobeJwtConnectionMutation,
  useAddAdobeOauthConnectionMutation,
  useDeleteAdobeJwtConnectionMutation,
  useDeleteAdobeOauthConnectionMutation,
  useRemoveAdobeConnectionMutation,
  useUpdateAdobeJwtConnectionMutation,
  useUpdateAdobeOauthConnectionMutation,
} from "../../graphql";
import {
  AdobeAccount,
  AdobeJwtAccount,
  AdobeOauthAccount,
  ConnectedApps,
} from "./types";

type AdobeAccounts = Pick<
  ConnectedApps,
  | "adobeAccounts"
  | "addAdobeJwtAccount"
  | "updateAdobeJwtAccount"
  | "removeAdobeAccount"
  | "deleteAdobeJwtAccount"
  | "addAdobeOauthAccount"
  | "updateAdobeOauthAccount"
  | "deleteAdobeOauthAccount"
>;

export function useAdobeAccounts(
  data: GetConnectedAppsQuery | undefined,
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"],
  t: TFunction<"connectedApps">,
): AdobeAccounts {
  const [addAdobeJwtConnection] = useAddAdobeJwtConnectionMutation({
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("adobe.errorAdd")} />),
  });

  const [addAdobeOauthConnection] = useAddAdobeOauthConnectionMutation({
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("adobe.errorAdd")} />),
  });

  const [updateAdobeJwtConnection] = useUpdateAdobeJwtConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorUpdate")} />,
      ),
  });

  const [updateAdobeOauthConnection] = useUpdateAdobeOauthConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorUpdate")} />,
      ),
  });

  // FIXME: Remove after deprecated adobe connections are removed from the api.
  const [removeAdobeConnection] = useRemoveAdobeConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorRemove")} />,
      ),
  });

  const [deleteAdobeJwtConnection] = useDeleteAdobeJwtConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorRemove")} />,
      ),
  });

  const [deleteAdobeOauthConnection] = useDeleteAdobeOauthConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorRemove")} />,
      ),
  });

  async function addAdobeJwtAccount(
    clientId: string,
    clientSecret: string,
    orgId: string,
    privateKey: string,
    technicalAccountId: string,
  ): Promise<boolean> {
    const response = await addAdobeJwtConnection({
      variables: {
        clientId,
        clientSecret,
        orgId,
        privateKey,
        technicalAccountId,
      },
      update: (cache, { data }) => addAdobeJwtConnectionToCache(cache, data),
    });

    return Boolean(
      response.data?.createAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  async function addAdobeOauthAccount(
    clientId: string,
    clientSecret: string,
  ): Promise<boolean> {
    const response = await addAdobeOauthConnection({
      variables: {
        clientId,
        clientSecret,
      },
      update: (cache, { data }) => addAdobeOauthConnectionToCache(cache, data),
    });

    return Boolean(
      response.data?.createAdobeOauthServerToServerConnection
        .adobeOauthServerToServerConnection.id,
    );
  }

  async function updateAdobeJwtAccount(
    adobeConnectionId: string,
    clientId: string,
    clientSecret: string,
    orgId: string,
    privateKey: string,
    technicalAccountId: string,
  ): Promise<boolean> {
    const response = await updateAdobeJwtConnection({
      variables: {
        adobeConnectionId,
        clientId,
        clientSecret,
        orgId,
        privateKey,
        technicalAccountId,
      },
      update: (cache, { data }) => updateAdobeJwtConnectionInCache(cache, data),
    });

    return Boolean(
      response.data?.updateAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  async function updateAdobeOauthAccount(
    adobeConnectionId: string,
    clientId: string,
    clientSecret: string,
  ): Promise<boolean> {
    const response = await updateAdobeOauthConnection({
      variables: {
        adobeConnectionId,
        clientId,
        clientSecret,
      },
      update: (cache, { data }) =>
        updateAdobeOauthConnectionInCache(cache, data),
    });

    return Boolean(
      response.data?.updateAdobeOauthServerToServerConnection
        .adobeOauthServerToServerConnection.id,
    );
  }

  // FIXME: Remove after deprecated adobe connections are removed from the api.
  async function removeAdobeAccount(
    adobeConnectionId: string,
  ): Promise<boolean> {
    const response = await removeAdobeConnection({
      variables: {
        adobeConnectionId,
      },
      optimisticResponse: {
        deleteAdobeConnection: {
          adobeConnection: {
            id: adobeConnectionId,
          },
        },
      },
      update: (cache, { data }) => removeAdobeConnectionFromCache(cache, data),
    });

    return Boolean(response.data?.deleteAdobeConnection.adobeConnection.id);
  }

  async function deleteAdobeJwtAccount(
    adobeConnectionId: string,
  ): Promise<boolean> {
    const response = await deleteAdobeJwtConnection({
      variables: {
        adobeConnectionId,
      },
      optimisticResponse: {
        deleteAdobeJwtConnection: {
          adobeJwtConnection: {
            id: adobeConnectionId,
          },
        },
      },
      update: (cache, { data }) =>
        deleteAdobeJwtConnectionFromCache(cache, data),
    });

    return Boolean(
      response.data?.deleteAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  async function deleteAdobeOauthAccount(
    adobeConnectionId: string,
  ): Promise<boolean> {
    const response = await deleteAdobeOauthConnection({
      variables: {
        adobeConnectionId,
      },
      optimisticResponse: {
        deleteAdobeOauthServerToServerConnection: {
          adobeOauthServerToServerConnection: {
            id: adobeConnectionId,
          },
        },
      },
      update: (cache, { data }) =>
        deleteAdobeOauthConnectionFromCache(cache, data),
    });

    return Boolean(
      response.data?.deleteAdobeOauthServerToServerConnection
        .adobeOauthServerToServerConnection.id,
    );
  }

  return {
    adobeAccounts: [
      ...getAdobeConnections(data),
      ...getAdobeJwtConnections(data),
      ...getAdobeOauthConnections(data),
    ],
    addAdobeJwtAccount,
    addAdobeOauthAccount,
    updateAdobeJwtAccount,
    updateAdobeOauthAccount,
    removeAdobeAccount,
    deleteAdobeJwtAccount,
    deleteAdobeOauthAccount,
  };
}

export function getAdobeConnections(
  data: GetConnectedAppsQuery | undefined,
): AdobeAccount[] {
  return (
    data?.me?.adobeConnections?.nodes.map((x) => ({
      type: "deprecated" as const,
      id: x.id,
      username: x.company ? `${x.username}:${x.company}` : x.username,
      secret: `*****${x.secretLastFragment}`,
      isValid: x.isWorking,
    })) ?? []
  );
}

export function getAdobeJwtConnections(
  data: GetConnectedAppsQuery | undefined,
): AdobeJwtAccount[] {
  return (
    data?.me?.adobeJwtConnections?.nodes.map((x) => ({
      type: "modern" as const,
      id: x.id,
      clientSecret: `*****${x.secretLastFragment}`,
      privateKey: `****`,
      clientId: x.clientId,
      orgId: x.orgId,
      technicalAccountId: x.technicalAccountId,
      isValid: x.isWorking,
    })) ?? []
  );
}

export function getAdobeOauthConnections(
  data: GetConnectedAppsQuery | undefined,
): AdobeOauthAccount[] {
  return (
    data?.me?.adobeOauthServerToServerConnections?.nodes.map((x) => ({
      type: "oauth" as const,
      id: x.id,
      clientId: x.clientId,
      clientSecret: `*****${x.secretLastFragment}`,
      isValid: x.isWorking,
    })) ?? []
  );
}

function addAdobeJwtConnectionToCache(
  cache: ApolloCache<AddAdobeJwtConnectionMutation>,
  data: AddAdobeJwtConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: [
            ...cachedData.me.adobeJwtConnections.nodes,
            data?.createAdobeJwtConnection.adobeJwtConnection,
          ],
        },
      },
    },
  });
}

function addAdobeOauthConnectionToCache(
  cache: ApolloCache<AddAdobeOauthConnectionMutation>,
  data: AddAdobeOauthConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeOauthServerToServerConnections: {
          ...cachedData.me.adobeOauthServerToServerConnections,
          nodes: [
            ...cachedData.me.adobeOauthServerToServerConnections.nodes,
            data?.createAdobeOauthServerToServerConnection
              .adobeOauthServerToServerConnection,
          ],
        },
      },
    },
  });
}

function updateAdobeJwtConnectionInCache(
  cache: ApolloCache<UpdateAdobeJwtConnectionMutation>,
  data: UpdateAdobeJwtConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });

  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: [
            ...cachedData.me.adobeJwtConnections.nodes.map((node) => {
              if (
                node.id !== data?.updateAdobeJwtConnection.adobeJwtConnection.id
              ) {
                return node;
              }
              return {
                ...node,
                ...data?.updateAdobeJwtConnection.adobeJwtConnection,
              };
            }),
          ],
        },
      },
    },
  });
}

function updateAdobeOauthConnectionInCache(
  cache: ApolloCache<UpdateAdobeOauthConnectionMutation>,
  data: UpdateAdobeOauthConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });

  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeOauthServerToServerConnections: {
          ...cachedData.me.adobeOauthServerToServerConnections,
          nodes: [
            ...cachedData.me.adobeOauthServerToServerConnections.nodes.map(
              (node) => {
                if (
                  node.id !==
                  data?.updateAdobeOauthServerToServerConnection
                    .adobeOauthServerToServerConnection.id
                ) {
                  return node;
                }
                return {
                  ...node,
                  ...data?.updateAdobeOauthServerToServerConnection
                    .adobeOauthServerToServerConnection,
                };
              },
            ),
          ],
        },
      },
    },
  });
}

// FIXME: Remove after deprecated adobe connections are removed from the api.
function removeAdobeConnectionFromCache(
  cache: ApolloCache<RemoveAdobeConnectionMutation>,
  data: RemoveAdobeConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId = data?.deleteAdobeConnection.adobeConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeConnections: {
          ...cachedData.me.adobeConnections,
          nodes: cachedData.me.adobeConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}

function deleteAdobeJwtConnectionFromCache(
  cache: ApolloCache<DeleteAdobeJwtConnectionMutation>,
  data: DeleteAdobeJwtConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId = data?.deleteAdobeJwtConnection.adobeJwtConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: cachedData.me.adobeJwtConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}

function deleteAdobeOauthConnectionFromCache(
  cache: ApolloCache<DeleteAdobeOauthConnectionMutation>,
  data: DeleteAdobeOauthConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId =
    data?.deleteAdobeOauthServerToServerConnection
      .adobeOauthServerToServerConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeOauthServerToServerConnections: {
          ...cachedData.me.adobeOauthServerToServerConnections,
          nodes: cachedData.me.adobeOauthServerToServerConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}
