import React from "react";
import { useTranslation, Chip, Typography } from "@lumar/shared";

import GoogleLogo from "./logo/Google.png";
import {
  AccountStatus,
  Cell,
  ConnectedAppsContainer,
  RemoveButton,
} from "./ConnectedAppsContainer";
import { ConnectedAppsContext } from "./ConnectedApps";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function GoogleAccounts(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const { googleAccounts, addGoogleAccount, removeGoogleAccount } =
    React.useContext(ConnectedAppsContext);

  return (
    <ConnectedAppsContainer
      logo={GoogleLogo}
      logoAlt="Google Logo"
      title={t("google.title")}
      noAccountMessage={t("google.noAccountMessage")}
      primaryButton={{
        label: t("google.addAccount"),
        onClick: () => addGoogleAccount(),
      }}
      accounts={googleAccounts}
      renderRow={(account) => (
        <>
          <Cell width="auto">
            <Typography variant="captionSemiBold">{account.name}</Typography>
            <div style={{ marginTop: -8 }}>
              <Chip
                label={t("google.analytics")}
                color="primary"
                className={classes.chip}
              />
              <Chip
                label={t("google.searchConsole")}
                color="primary"
                className={classes.chip}
              />
            </div>
          </Cell>
          <Cell width="12%">
            <AccountStatus isValid={account.isValid} />
          </Cell>
          <Cell width="40px">
            <RemoveButton
              title={t("google.removeTitle")}
              description={t("google.removeDescription")}
              onRemove={() => removeGoogleAccount(account.id)}
            />
          </Cell>
        </>
      )}
      data-testid="connected-apps-google"
    />
  );
}
